@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Karla", sans-serif;
  scroll-behavior: smooth;
  height: "100%";
}

.paragraph {
  line-height: 35px;
  margin-bottom: 25px;
  letter-spacing: 0.8px;
}

.accent {
  font-weight: bold;
}

.reader-highlight {
  background-color: rgba(255, 255, 0, 0.605);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.reader-highlight.highlight-reader-hover {
  background-color: rgba(255, 255, 0, 0.95);
  cursor: pointer;
}

.reader-comment {
  background-color: rgba(0, 213, 255, 0.4);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.reader-comment.highlight-reader-comment {
  background-color: rgba(0, 213, 255, 0.605);
  cursor: pointer;
}

.reader-highlight.disabled,
.reader-comment.disabled {
  background-color: transparent;
  cursor: auto;
}

.annotation-card-hover {
  border: 1px solid #9a97cf;
}

.tooltip {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.investors {
  border-collapse: separate;
  border-spacing: 10px 5px;
}

@layer base {
  html,
  body {
    @apply bg-pallet-500 text-text-dark;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="text"],
input[type="number"] {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/* .shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
} */

.login-input {
  background-color: #ebeef8 !important;
  border-radius: 6px !important;
}

.custom-shadow {
  --tw-shadow: 0 4px 10px -1px rgb(0 0 0 / 0.1),
    0 2px 10px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 10px -1px var(--tw-shadow-color),
    0 2px 10px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tabbed {
  min-width: 400px;
  margin: 0 auto;
  overflow: hidden;
  transition: border 250ms ease;
}
.tabbed ul {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  float: left;
  padding-left: 0px;
  list-style-type: none;
}

.tabbed ul * {
  margin: 0px;
  padding: 0px;
}

.tabbed ul li {
  display: block;
  float: right;
  padding: 10px 24px 8px;
  background-color: #fff;
  margin-right: 28px;
  z-index: 2;
  position: relative;
  cursor: pointer;
  color: #777;
  border-radius: 100px 100px 0 0;
  text-transform: uppercase;
  font: 600 13px/15px karla, "Open Sans", Helvetica, sans-serif;
  border-radius: 8px 8px 0 0;
  transition: all 250ms ease;
}

.tabbed ul li {
  font: 600 11.8px/20px karla, "Open Sans", Helvetica, sans-serif;
}

.price-chart {
  width: calc(100% - 320px);
}

.sankey-widget {
  width: calc(100% - 320px);
}

.paulGrahamParagraph {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 25px;
  letter-spacing: 0.8px;
  font-family: "Karla", sans-serif;
  text-align: justify;
}

@media only screen and (min-width: 768px) {
  .paulGrahamParagraph {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .price-chart {
    width: 100%;
  }
  .sankey-widget {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .appshell-sidebar {
    top: auto !important;
    height: 60px;
  }
  .appshell-container {
    height: 60px;
  }
}

@media only screen and (min-width: 1024px) {
  .price-chart {
    width: calc(100% - 360px);
  }
  .sankey-widget {
    width: calc(100% - 355px);
  }
}

@media only screen and (min-width: 1280px) {
  .analyst-card {
    width: calc(50% - 8px);
  }
}
.tabbed ul li:before,
.tabbed ul li:after {
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 40px;
  background-color: #fff;
  transition: all 250ms ease;
  border-radius: 8px 8px 0 0;
}
.tabbed ul li:before {
  right: -18px;
  transform: skew(30deg, 0deg);
  box-shadow: rgba(0, 0, 0, 0.1) 3px 2px 5px,
    inset rgba(255, 255, 255, 0.09) -1px 0;
}
.tabbed ul li:after {
  left: -18px;
  transform: skew(-30deg, 0deg);
  box-shadow: rgba(0, 0, 0, 0.1) -3px 2px 5px,
    inset rgba(255, 255, 255, 0.09) 1px 0;
}
.tabbed ul li:hover,
.tabbed ul li:hover:before,
.tabbed ul li:hover:after {
  background-color: #f4f7f9;
  color: #444;
}
.tabbed ul li.active {
  z-index: 3;
}
.tabbed ul li.active,
.tabbed ul li.active:before,
.tabbed ul li.active:after {
  background-color: #e9795d;
  color: #fff;
}

a,
button {
  text-decoration-thickness: 0.8px;
}
